@import '../../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.error {
  color: var(--colorFail);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.privacyCompliance {
  margin-bottom: 12px;
  text-align: center;
  font-size: 12px;
  max-width: 300px;
  margin: auto;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}